@font-face {
    font-family: 'big-noodle-titling';
    src: local('big-noodle-titling'), local('Big-Noodle-Titling'),
        url('big_noodle_titling.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kenteken';
    src: local('kenteken'), local('kenteken'),
        url('kenteken.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

